import React from "react"
import HeroComponent from "../components/HeroComponent"
import ContactComponent from "../components/ContactComponent"
import ServiceComponent from "../components/ServiceComponent"
import FolioComponent from "../components/FolioComponent"
import Youtube from "../components/Youtube"
import WebGLLoader from "../components/WebGLoader"

export default function Home()
{

  return (
    <>
      <HeroComponent />
      <WebGLLoader />
      <ServiceComponent />
      <FolioComponent />
      <Youtube />
      <ContactComponent />
    </>
  );

}
