import React from "react"
import Image from "./Image"
import '../styles/hero.css'
import ReactTextRotator from 'react-text-rotator';
import { heroHeaderData, heroSubtitleData } from "../data/GlobalData"

export default function HeroComponent()
{

  const subtitleItems = heroSubtitleData.map(subtitleData => <div className="descriptionText" key={subtitleData}>{subtitleData}</div>);

  return (
    <>
      <div className="hero-container">
          <div className="hero-content-wrapper">
            <div className="hero-block">
              <div className="hero-logo-wrapper">
                <Image fileName="logo.png" alt="Cyber.Mage" style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: 'contain'}} />
              </div>
              <div className="headingWrapper">
                <ReactTextRotator content={heroHeaderData} time={5000} startDelay={100}/>
              </div>
              {subtitleItems}
            </div>
          </div>
      </div>
    </>
  )
}
