import {FaLaptopCode} from "react-icons/fa"
import {ImFilm} from "react-icons/im"
import {CgGames} from "react-icons/cg"
import {MdWeb} from "react-icons/md"

export const ProjectData = [
  {
  title: "Varsity: Immersive Learning",
  description: ["Workforce Rollout","Validated Learning", "Rapid Prototyping"],
  ios: true,
  android: false,
  oculus: true,
  mixed: false,
  image: "varsity.png",
  url: ""
  },
  {
  title: "Virtual Hobart Town",
  description: ["Device Orientation","Analytics", "GraphQL"],
  ios: true,
  android: true,
  oculus: false,
  mixed: false,
  image: "virtual_hobart.png",
  url: ""
  },
  {
    title: "Pisidia Heritage Trail",
    description: ["User Authentication","Analytics","Sentry API"],
    ios: true,
    android: true,
    oculus: false,
    mixed: false,
    image: "biaa.png",
    url: ""
  },
  {
    title: "City VR Tours",
    description: ["Geofencing","Route Prediction","Dynamic Content"],
    ios: false,
    android: false,
    oculus: true,
    mixed: false,
    image: "cityvr.png",
    url: ""
  },
  {
    title: "Roma Experience",
    description: ["GPS Translation", "VR User Interface", "Interactive System"],
    ios: false,
    android: false,
    oculus: true,
    mixed: false,
    image: "roma.png",
    url: ""
  },
  {
    title: "The Unknown Patient",
    description: ["Virtual Narrative","Interaction Manager", "Audio Manager"],
    ios: false,
    android: false,
    oculus: true,
    mixed: true,
    image: "unknown_patient.png",
    url: ""
  },
  {
    title: "Power Rangers AR",
    description: ["Kinect", "Audio Manager", "Sound Design"],
    ios: false,
    android: false,
    oculus: false,
    mixed: true,
    image: "power_ranger.png",
    url: ""
  },
  {
    title: "Starbike",
    description: ["VR Lobby", "VR User Interface", "Geofencing"],
    ios: false,
    android: true,
    oculus: true,
    mixed: false,
    image: "starbike.png",
    url: ""
  },
  {
    title: "Engage 360",
    description: ["In App Purchases", "Content Delivery", "Analytics"],
    ios: true,
    android: true,
    oculus: false,
    mixed: false,
    image: "engage.png",
    url: ""
  },
];

export const ServiceData = [
  {
    title: "Developer",
    subtitle: "C#, C++, Rust, JS, Gatsby, Next, React, React Native, REST, Graphql, MongoDB",
    description: "Full-stack developer with a passion for immersive technology. Responsible for releasing 15+ cross-platform consumer products (Mobile/VR/AR/Web). I've created data-driven frameworks, user-authentication stacks, content delivery networks, analytical models, audio systems, UI frameworks and common game features. I love learning, resolving root causes and implementing long term solutions to cultivate meaningful user experiences.",
    icon: FaLaptopCode
  },
  {
    title: "Creative Technologist",
    subtitle: "Prototyping, AR, VR, 360, Projections, Kinect, Prototyping, Analytics, MaxMSP, Photoshop, Protools",
    description: "I've developed creative immersive products for consumers, museums, tourism spaces, and public installations. I'm fascinated by the intersection of people and technology and personally promote holistic user experiences that can be enjoyed by all demographics. I'm a creative with a concrete understanding of design principles, film techniques and consumer marketing.",
    icon: CgGames
  },
  {
    title: "Service Design",
    subtitle: "Virtual Production, Film, Writing, Lighting, Spatial Interaction, Sound Design.",
    description: "To create memorable products – we need a story, an experience, a dramatic arc. I'm a gamer at heart, software developer, audio post-production engineer and film industry journeyman. I embrace a narrative approach to product development and use my past learnings to promote user-centred design solutions. I've created virtual productions, 360 narratives and immersive social experiences.",
    icon: ImFilm
  },
  {
    title: "Product Management",
    subtitle: "Gatsby, Next",
    description: "Web and Graphics, need a photoshop dude?",
    icon: MdWeb
  }
];

export const heroHeaderData = [
  {
    text: "Negative. I'm a meat popsicle.",
    className: 'headingText',
    animation: 'fade',
  },
  {
    text: 'Are you classified as human?',
    className: 'headingText',
    animation: 'fade',
  },
]

export const heroSubtitleData = [
  "dev ▒ film ▒ audio ▒ web",
  "",
  "warning: implant products lead to hybrid humans"
]
