import React from "react"
import '../styles/service.css'
import Service from "./Service"
import { ServiceData } from "../data/GlobalData"

export default function ServiceComponent()
{
  return (
    <>
      <div className="service-container">
        <div className="service-item-collection">
          <Service {...ServiceData[0]} />
          <Service {...ServiceData[1]} />
          <Service {...ServiceData[2]} />
        </div>
      </div>
    </>
  )
}