import React from "react"
import {MdPhoneIphone, MdMail} from "react-icons/md"
import {FaHome} from "react-icons/fa"
import {SiLinkedin, SiImdb, SiGit} from "react-icons/si"
import '../styles/contact.css'

export default function ContactComponent()
{
  return (
    <>
      <div className="br-block" />
      <div className="contact-container">
        <div className="contact-content-wrapper">
          <div className="contact-links">
            <a href="https://au.linkedin.com/in/leon-east-096a2788" target="_blank" rel="noreferrer">
              <SiLinkedin />
            </a>
            <a href="https://www.imdb.com/name/nm3864209/" target="_blank" rel="noreferrer">
              <SiImdb />
            </a>
            <a href="https://github.com/npig" target="_blank" rel="noreferrer">
              <SiGit />
            </a>
          </div>
          <ul className="contact-list">
            <li className="contact-list-item">
              <MdMail className="contact-icon" />
              &nbsp;
              <a
                href="mailto:mail@cybermage.live"
                target="_blank"
                rel="noreferrer"
              >mail@cybermage.live
              </a>
            </li>
            <li className="contact-list-item">
              <MdPhoneIphone className="contact-icon" />
              &nbsp;
              <a
              href="tel:+61412206001"
              target="_blank"
              rel="noreferrer">
                +61 412 206 001
            </a>
            </li>
            <li className="contact-list-item">
              <FaHome className="contact-icon" /> Melbourne, Australia
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
