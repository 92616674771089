import React from "react"
import {FaGooglePlay, FaAppStoreIos} from "react-icons/fa"
import {SiOculus} from "react-icons/si"
import {TiVendorMicrosoft} from "react-icons/ti"
import Image from "./Image"

import '../styles/project.css'


export default function Project({
  title,
  description,
  ios,
  android,
  oculus,
  mixed,
  image})
{

  const listItems = description.map((feature) => <li key={feature}>{feature}</li>);

  return (
    <>
      <div className="project">
        <div className="project-image">
          <Image fileName={image} style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: 'contain'}} />
        </div>
        <div className="project-content-panel">
          <div className="project-content-platforms">
            { ios ? <FaAppStoreIos className="project-content-platforms-item" /> : null}
            { android ? <FaGooglePlay className="project-content-platforms-item" /> : null}
            { oculus ? <SiOculus className="project-content-platforms-item" /> : null}
            { mixed ? <TiVendorMicrosoft className="project-content-platforms-item" /> : null}
          </div>
          <div className="project-content-title">{title}</div>
          <div className="project-content-description">
            <ul>
              {listItems}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

