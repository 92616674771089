import React from "react"
import Project from "./Project"
import { ProjectData } from "../data/GlobalData"
import '../styles/folio.css'

export default function FolioComponent()
{

  const projectItems = ProjectData.map(projectItem => <Project key={projectItem.title} {...projectItem} />
  );

  return (
    <>
      <div className="folio-container">
        <div className="folio-content-wrapper">
          <div className="project-container">
            <div className="project-collection">
              {projectItems}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
