import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ fileName, alt, style, imgStyle}) => {

  const { allImageSharp } = useStaticQuery(graphql`
      query {
          allImageSharp {
              nodes {
                  fluid(maxWidth: 1600) {
                      originalName
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  const fluidImg = allImageSharp.nodes.find(n => n.fluid.originalName === fileName).fluid

  return (
      <Img fluid={fluidImg} alt={alt} style={style} imgStyle={imgStyle} />
  )
}

export default Image;