import '../styles/webgl.css';
import React from "react"
import loadable from '@loadable/component'
import {isMobile} from 'react-device-detect';

export default function WebGLLoader()
{
  const LoadableComponent = loadable(() => import('../components/WebGLComponent'))
  const MobileMessage = () => {
    return(
      <div>Please use your desktop computer to access the interactive component</div>
    );
  }
  return (
    <>
      <div className="webgl-container">
        <div className="br-block" />
        {isMobile === true ? <MobileMessage /> : <LoadableComponent />}
      </div>
    </>
  )
}