import React from "react"
import '../styles/service.css'
import '../styles/global.css'
export default function Service({
                                  title,
                                  subtitle,
                                  description,
                                  icon})
{

  const Icon = icon;

  return (
    <>
      <div className="service-item">
        <div className="service-icon">
          <Icon />
        </div>
        <div className="service-title">{title}</div>
        <div className="service-subtitle">{subtitle}</div>
        <div className="hr-block"/>
        <div className="service-description">{description}</div>
      </div>
    </>
  )
}
