import React from "react"
import '../styles/youtube.css'

export default function Youtube()
{
  return (
    <>
      <div className="youtube-container">
        <div className="youtube-collection">
          <div className="youtube-item">
            <iframe
              width="560" height="315" 
              src="https://www.youtube.com/embed/hoMHO764Lf8"
              title="sample" 
              frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
            />
          </div>
          <div className="youtube-item">
            <iframe 
              width="560" height="315" 
              src="https://www.youtube.com/embed/BwEyk1zpdvY" 
              title="film sample" 
              frameborder="0" 
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen
            /> 
          </div>
        </div>
      </div>
    </>
  )
}